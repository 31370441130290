
export const Data = {

    NavData : [
        {title: 'Apie mus', link: '/apiemus'},
        {title: 'Paslaugos', link: '/paslaugos', subMenu:[
                {title: 'Plytelių klijavimas', link: '/'},
                {title: 'Sienų ir lubų glaistymas/dažymas', link: '/'},
                {title: 'Gipso kartono montavimas', link: '/'},
                {title: 'Grindų betonavimas', link: '/'},
                {title: 'Sienų tinkavimas', link: '/'},
                {title: 'Įtempiamų lubų montavimas', link: '/'},
                {title: 'Laminato klojimas', link: '/'},
                {title: 'Tapetavimas', link: '/'},
                {title: 'Kapitalinis buto remontas', link: '/'},
                {title: 'Kosmetinis buto remontas', link: '/'},
            ]},
        {title: 'Darbai', link: '/darbai', subMenu:[
                {title: 'Straipsniai', link: '/'},
                {title: 'Atsilepimai', link: '/'},
                {title: 'Galerija', link: '/darbai'},
            ]},
        {title: 'Kainos', link: '/kainos', subMenu:[
                {title: 'Skaičiuoklė', link: '/'},
                {title: 'Sąmatą', link: '/kainos/samata'},

            ]},
        {title: 'Kontaktai', link: '/kontaktai'},

    ],

    Tel: {Num: '+370 670 06363', Text: 'Paskambinti'},
    Email: 'info@tecmon.lt',
    EmailText: '?, &subject=Хочу задать вопрос&body=Добрый день,',
    Address: {text:'Rodūnios kelias 34, LT-02187 Vilnius', link: 'https://goo.gl/maps/HhtHY7Upm3wcRdaFA'},
    Logo: {img: '/img/logo.png', alt: 'Statyba ir apdailos darbai Vilniuje', to: '/'},
    
    FirstScreen: {
        title: 'Statybos ir apdailos darbai Vilniuje',
        subtitle: 'Atliekame Visus Apdailos ir Remonto Darbus. Pilnai įrengiame butus, namus, biuro ir komercinės paskirties patalpas. Pilna apdaila, nuo dalinės iki rakto',
        img: {img: '/img/main-bg.png', alt: 'Apdailos darbai paveikslas'},

    },



    Service:{
        Title: 'We have a vast offer that covers all renovation needs',
        serviceItem:[
            {itemTitle:'Construction', itemText:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, seagna aliqua maecenas accum lacus vel facilisis.', itemPrice: 'Prices from $30/m2', itemPic: '/img/ServicePics/construction.png'},
            {itemTitle:'Electrics', itemText:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, seagna aliqua maecenas accum lacus vel facilisis.', itemPrice: 'Prices from $30/m2', itemPic: '/img/ServicePics/electric.png'},
            {itemTitle:'Plumbing', itemText:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, seagna aliqua maecenas accum lacus vel facilisis.', itemPrice: 'Prices from $30/m2', itemPic: '/img/ServicePics/plumbing.png'},
        ]
    },

    Carousel: [
        'img/Carousel/reno4-home-slide1.jpg',
        'img/Carousel/reno4-home-slide2.jpg',
        'img/Carousel/reno4-home-slide3.jpg',
        'img/Carousel/reno4-home-slide1.jpg',
        'img/Carousel/reno4-home-slide2.jpg',
        'img/Carousel/reno4-home-slide3.jpg',
    ],
    CarouselTitle: {
        Title: 'Take a look at our realizations',
        Button: 'Daugiau Darbų',
        Text: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur excepteur sint.' },

    ContactUs: {
        pic: 'img/contact-us.png',
        title: 'Turite klausimų? Skambinkite mums...',
        picTel: '',
        picMail: '',
        time: '+370 624 32277'
    },


    Contacts:{
        TelMailPic: '/img/img_box_29_violet.png',
        AddressPic: '/img/img_box_30_violet.png',
    },
    Social: [
        {name: 'Facebook', link: '/', img: '/img/img_box_30_violet.png'},
        {name: 'Telegram', link: '/', img: '/img/img_box_30_violet.png'},
        {name: 'Instagram', link: '/', img: '/img/img_box_30_violet.png'},

    ],
    About:{
        Title: 'Pas mus dirba tik laiku patikrinti bei didelį patirtį turintys kvalifikuoti meistrai. Naudojame tik profesionalius įrankius, techniką.',
        Text: 'Profesionaliai ir kokybiškai atliekame butų, ofisų, privačių namų pilną vidaus apdailą nuo griovimo iki postatybinio valymo darbų: gipso kartono įvairių konstrukcijų montavimas (pertvaros, sienos, lubos, šlaitinės lubos, nišos); sienų ir lubų glaistymas/dažymas,  sienų ir lubų perdažymas (kosmetinis remontas)',
        img: '/img/main-team.png'
    },
    Footer: {
        Address: {
            Title: 'Adresas',
            Address: 'Rodūnios kelias 34, LT-02187 Vilnius',
        },
        LinkTitle: 'Nuorodos',
        Links: [
            {linkName: 'Apie mus', lnk: '/apiemus'},
            {linkName: 'Paslaugos', lnk: '/paslaugos'},
            {linkName: 'Darbai', lnk: '/darbai'},
            {linkName: 'Kainos', lnk: '/kainos'},
            {linkName: 'Kontaktai', lnk: '/kontaktai'},
        ],
        Phone: {PhoneTitle: 'Kontaktai', phoneText: 'Turite klausimų, susisiekite su mumis info@tecmon.lt'},
    },
    Copyright: {Text: '© 2021 Imta'},
    Modal: {
        title: 'Заполни форму',
        desc: 'Мы свяжемся с вами и обсудим как наилучшем способом привлечь клиентов',
        text: 'Начни прямо сейчас',
    },

    AboutPage: {
        TitleSubtitlePicture: {
            Title: 'Jūsų idėjų įgyvendinimas – mūsų darbas',
            SubTitle: 'Pas mus dirba tik laiku patikrinti bei didelę patirtį turintys kvalifikuoti meistrai. Naudojame tik profesionalius įrankius, techniką Kiekvienas klientas yra svarbus, jam skiriamas dėmesys, nepriklauso nuo objekto darbų apimties ir bendros suteikiamų paslaugų kainos. Dirbame pramoniniuose bei privačiuose objektuose',
            Picture: '/img/workers.png', alt: 'Statybininkai stato nama'
        },
        SimpleText: 'Profesionaliai ir kokybiškai atliekame butų, ofisų, privačių namų pilną vidaus apdailą nuo griovimo iki postatybinio valymo darbų: gipso kartono įvairių konstrukcijų montavimas (pertvaros, sienos, lubos, šlaitinės lubos, nišos); sienų ir lubų glaistymas/dažymas,  sienų ir lubų perdažymas (kosmetinis remontas); visų tipų plytelių klijavimas;  elektros instaliacijos darbai (naujos instaliacijos įrengimas, rozečių pajungimas/montavimas, įvairių tipų šviestuvų montavimas, elektrinių prietaisų pajungimas, LED apšvietimo įrengimas/montavimas, automatų skydelio montavimas); santechnikos darbai (senos santechnikos demontavimas, vamzdžių perkelimas/vamzdyno rekonstrukcija, santechninių prietaisų pajungimas/montavimas), grindų betonavimas; grindų dangos klojimas (laminatas, linoleumas, parketlentės)'
    },
    PaslaugosPage: {
        TitleSubtitlePicture: {
            Title: 'Jūsų idėjų įgyvendinimas – mūsų darbas',
            SubTitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua maecenas accumsan lacus vel facilisis.',
            Picture: '/img/workers.png', alt: 'Statybininkai stato nama'
        },
        SimpleText: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
    },

    Darbai: {
        Title: 'Mūsų darbai',
        ContactsText: 'Turite klausimų?',
        Tel: '+370 670 06363',
        Email: 'info@tecmon.lt',
        Photos: [
            './img/gallery/reno4-gallery-image-1.jpeg',
            './img/gallery/reno4-gallery-image-2.jpeg',
            './img/gallery/reno4-gallery-image-3.jpeg',
            './img/gallery/reno4-gallery-image-4.jpeg',

        ]
    },
    KainosPage: {
        TitleSubtitlePicture: {
            Title: 'Offer & pricing',
            SubTitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua maecenas accumsan lacus vel facilisis',
            Picture: '/img/workers.png', alt: 'Statybininkai stato nama'
        },
        SimpleText: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        FeatureItem: [
            {
                img: './img/ServicePics/construction.png',
                Title: 'A few words about us',
                Text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua maecenas accumsan lacus vel facilisis',
                listItems: [
                    'Lorem ipsum',
                    'Dolor sit amet',
                    'Consecteur',
                    'Lorem ipsum',
                    'Dolor sit amet',
                    'Consecteur'
                ],
                Price: 'Prices from $30/m2',
            },
            {
                img: './img/ServicePics/construction.png',
                Title: 'A few words about us',
                Text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua maecenas accumsan lacus vel facilisis',
                listItems: [
                    'Lorem ipsum',
                    'Dolor sit amet',
                    'Consecteur'
                ],
                Price: 'Prices from $30/m2',
            },
            {
                img: './img/ServicePics/construction.png',
                Title: 'A few words about us',
                Text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua maecenas accumsan lacus vel facilisis',
                listItems: [
                    'Lorem ipsum',
                    'Dolor sit amet',
                    'Consecteur'
                ],
                Price: 'Prices from $30/m2',
            }
        ]
    },

    MainGalleryData: [
        'img/Carousel/reno4-home-slide1.jpg',
        'img/Carousel/reno4-home-slide2.jpg',
        'img/Carousel/reno4-home-slide3.jpg',
        'img/Carousel/reno4-home-slide1.jpg',
        'img/Carousel/reno4-home-slide2.jpg',
        'img/Carousel/reno4-home-slide3.jpg',
    ],

}