import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'

import { Gallery, Item } from 'react-photoswipe-gallery';

import './worksGallery.css';

const WorksGallery = (props) => {

    const galleryData = props.data;

    const Items = galleryData.Darbai.Photos.map((name, index) =>
        <Item
            key = {index}
            original={name}
            thumbnail={name}
            width="1024"
            height="768"
        >
            {({ref, open}) => (
                <img ref={ref} onClick={open} src={name} width='880' height='600'/>
            )}
        </Item>
    )

    return (
        <div className={"gallery"}>

            <h1 className={'gallery__title'}>{galleryData.Darbai.Title}</h1>

            <div className={"gallery__wrapper"}>
                <Gallery>
                    {Items}
                </Gallery>
            </div>

            <p className={"gallery__contacts"}>{galleryData.Darbai.ContactsText}</p>
            <div className={"gallery__contacts-wrapper"}>
                <span>{galleryData.Darbai.Tel}</span>
                <span>{galleryData.Darbai.Email}</span>
            </div>
        </div>
    )
}

export default WorksGallery;