import React from 'react';
import { FaFacebook, FaTelegram, FaYoutube, FaInstagram, FaFacebookMessenger } from "react-icons/fa";
import './FirstScreen.css'


const FirstScreen = props => {

    let Data = props.data



  return (
   <div className={'hero'}>

       {/*<img src={Data.FirstScreen.img.img} alt=""/>*/}

       <div className={'hero__wrapper'}>
           <h1 className={'hero__title'}>{Data.FirstScreen.title}</h1>
           <h2 className={'hero__subtitle'}>{Data.FirstScreen.subtitle}</h2>

           <button>Apskaičiuoti apdailos darbus </button>

           <div className={'hero__line'}></div>

           <div className={'social'}>
               <p>Susisiekite su mumis</p>
               <ul>

                   <li><a href={'/'} target="_blank" rel="noreferrer">
                       <FaFacebook/>
                   </a></li>
                   <li><a href={'/'} target="_blank" rel="noreferrer">
                       <FaTelegram/>
                   </a></li>
                   <li><a href={'/'} target="_blank" rel="noreferrer">
                       <FaYoutube/>
                   </a></li>
                   <li><a href={'https://www.instagram.com/tecmon_uab/'} target="_blank" rel="noreferrer">
                       <FaInstagram/>
                   </a></li>
                   <li><a href={'/'} target="_blank" rel="noreferrer">
                       <FaFacebookMessenger/>
                   </a></li>
               </ul>
           </div>

       </div>
   </div>
  );
 }


export default FirstScreen;