import React from 'react';
import style from './Footer.module.css';
import {NavLink} from "react-router-dom";

const Footer = props => {

    const Data = props.data
    const links = Data.Footer.Links.map((link, index) =>

        <li key={index}>
            <NavLink to={link.lnk}>
                {link.linkName}
            </NavLink>
        </li>
    )



  return (
   <footer className={style.footer}>
       <div className={style.footer__wrapper}>
           <div className={'footer__address'}>
               <h4>{Data.Footer.Address.Title}</h4>
               <span>{Data.Footer.Address.Address}</span>
           </div>
           <div className={'footer__links'}>
                <h4>{Data.Footer.LinkTitle}</h4>
                    <ul>
                        {links}
                    </ul>
           </div>
           <div className={style.footerTel}>
                <h4>{Data.Footer.Phone.PhoneTitle}</h4>
                <h5>{Data.Tel.Num}</h5>
                <span>{Data.Footer.Phone.phoneText}</span>
           </div>

       </div>

       <div className={'copyright'}>

       </div>

   </footer>
  );
 }


export default Footer;