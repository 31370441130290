import React from 'react';
import  {Data} from "../../../Data/TecmonLT/Data";
import FirstScreen from "../../../Components/FirstScreen/FirstScreen";
import About from "../../../Components/About/About";
import Service from "../../../Components/Service/Service";
import ContactUs from "../../../Components/ContactUs/ContactUs";
import MainGallery from "../../../Components/Carousel/CarouselSlider";
import CarouselSlider from "../../../Components/Carousel/CarouselSlider";

const TecmonMain = () => {


  return (
   <main>
        <FirstScreen
            data = {Data}
        />
        <About
            data={ Data }
        />
        <Service
            data = { Data }
        />

        <CarouselSlider
            data = {Data}
        />

        <ContactUs
            data = { Data }
        />

   </main>
  );
 }


export default TecmonMain;