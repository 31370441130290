import React from 'react';
import  {Data} from "../../../Data/TecmonLT/Data";
import FirstScreen from "../../../Components/FirstScreen/FirstScreen";
import About from "../../../Components/About/About";
import Service from "../../../Components/Service/Service";
import ContactUs from "../../../Components/ContactUs/ContactUs";
import TitleSubtitlePicture from "../../../Components/TitleSubtitlePicture/TitleSubtitlePicture";
import SimpleText from "../../../Components/SimpleText/SimpleText";


const Paslaugos = () => {


  return (
   <main>
        <TitleSubtitlePicture
            data={ Data.PaslaugosPage }
        />
        <SimpleText
            data={ Data }
        />
       <ContactUs
           data = { Data }
       />
   </main>
  );
 }


export default Paslaugos;