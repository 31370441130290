import React from 'react';
import './FeatureItem.css'

const FeatureItem = props => {

    let FeatureItemData = props.data


    const FeatureItm = FeatureItemData.map ((item, index) =>
        <div key={index+1} className={'feature-item'}>
            <div>
                <img src={item.img} alt=""/>
            </div>

            <div className={'feature-item__desc'}>
                <h3 className={'feature-item__title'}>{item.Title}</h3>
                <p className={'feature-item__text'}>{item.Text}</p>
                <ul className={'feature-item__list'}>
                    {item.listItems.map ((lItem, indexI) => <li key={indexI + 1}>{lItem}</li>)}
                </ul>
                <p className={'feature-item__price'}>{item.Price}</p>
            </div>
        </div>
    )

    return (
        <>
            <div className={"feature-items"}>
                <div className={"feature-items__wrapper"}>
                    {FeatureItm}
                </div>
            </div>
        </>

    );
}


export default FeatureItem;