import React from 'react';
import './SimpleText.css'

const SimpleText = props => {

    const Data = props.data

  return (
   <div className={'simple-text'}>
        <p>{Data.AboutPage.SimpleText}</p>
   </div>
  );
 }


export default SimpleText;