import React from 'react';
import './Tel.css'

const Tel = props => {
  return (
   <div className={'tel__wrapper'}>
       <a className={"tel"} href={"tel: " + props.tel}>{props.text}</a>
   </div>
  );
 }


export default Tel;