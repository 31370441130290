import React from 'react';
import './About.css'

const About = props => {

    let Data = props.data

  return (
   <div className={'about'}>
       <div className={'about__wrapper'}>
           <div>
               <img src={Data.About.img} alt=""/>
           </div>

            <div className={'about__desc'}>
                <h3 className={'about__title'}>{Data.About.Title}</h3>
                <p className={'about__text'}>{Data.About.Text}</p>
                <button>Apie Mus</button>
            </div>
       </div>
   </div>
  );
 }


export default About;