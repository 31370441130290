import React from 'react';
import './TitleSubtitlePicture.css'

const TitleSubtitlePicture = props => {

    const Data = props.data

  return (
   <div className={'tsp'}>
       <div className={'tsp__wrapper'}>
            <h1>{Data.TitleSubtitlePicture.Title}</h1>
            <span>{Data.TitleSubtitlePicture.SubTitle}</span>
       </div>
   </div>
  );
 }


export default TitleSubtitlePicture;