import React, {useState} from 'react';
import {NavLink} from "react-router-dom";
import './NavBar.css'


const NavBar = props => {

    const [activeMenu, setActiveMenu] = useState(false);

    const clickHandler = () => {
        setActiveMenu(!activeMenu)
    }

    const NavData = props.data;

    const buttonsNames = NavData.map((name, index) =>
        <li key={index} className={"header__item"}><NavLink to={`${name.link}`} className={"header__link"}>{name.title}</NavLink>

            {name.subMenu ?
                <div className={"header__subnav"}>
                    <ul className={"subnav"}>
                        {name.subMenu.map((item,index) =>
                            <li key={index+"sub"} className={"subnav__item"}><NavLink to={`${item.link}`} className={"subnav__link"}>{item.title}</NavLink></li>
                        )}
                    </ul>
                </div>: null}

        </li>
    )


    return (
        <>
            <ul className={activeMenu ? 'header__nav menuOpened' : 'header__nav menuClosed'}>
                {buttonsNames}
            </ul>
            <button className={"menuToggle"} onClick={clickHandler}>
                <span></span>
                <span></span>
                <span></span>
            </button>
        </>
   )
}

export default NavBar;