import React from 'react';
import Swiper from 'react-id-swiper';
import {NavLink} from "react-router-dom";
import './MainGallery.css'

const MainGallery = (props) => {
    const params = {
        initialSlide: 3,
        // slidesPerView: 3,
        // spaceBetween: 50,
        mousewheel: true,
        loop: true,
        breakpoints: {
            1140: {
                slidesPerView: 3,
                spaceBetween: 50
            },
            768: {
                slidesPerView: 3,
                spaceBetween: 30
            },
            640: {
                slidesPerView: 2,
                spaceBetween: 20
            },
            320: {
                slidesPerView: 1,
                spaceBetween: 0
            }
        }
    }

    const MainGalleryData = props.data;

    return (
        <div className={"gallery-wrapper"}>
            <Swiper {...params}>
                <div>
                    <NavLink to={`/darbai`}>
                        <img src={MainGalleryData[0]} alt="" />
                    </NavLink>
                </div>
                <div>
                    <NavLink to={`/darbai`}>
                        <img src={MainGalleryData[1]} alt=""/>
                    </NavLink>
                </div>
                <div>
                    <NavLink to={`/darbai`}>
                        <img src={MainGalleryData[2]} alt=""/>
                    </NavLink>
                </div>
                <div>
                    <NavLink to={`/darbai`}>
                        <img src={MainGalleryData[3]} alt=""/>
                    </NavLink>
                </div>
                <div>
                    <NavLink to={`/darbai`}>
                        <img src={MainGalleryData[4]} alt=""/>
                    </NavLink>
                </div>
            </Swiper>
        </div>
    )
};
export default MainGallery;