import React from 'react';
import './ContactUs.css'

const ContactUs = props => {

    const Data = props.data

  return (
   <div className={'contactUs'}>
       <div className={'contactUs__wrapper'}>
           <img src={Data.ContactUs.pic} alt="rasti statybininką"/>
           <div className={'contactUs__desc'}>
               <h3 className={'contactUs__title'}>{Data.ContactUs.title}</h3>
               <div>
                   <span>{Data.ContactUs.picTel}{Data.Tel.Num}</span>
                   <span>{Data.ContactUs.picMail}{Data.Email}</span>
               </div>
               <p>{Data.ContactUs.time}</p>
           </div>
       </div>
   </div>
  );
 }


export default ContactUs;