import './App.css';
import {Route, Switch, Redirect} from 'react-router-dom';
import TecmonMain from "./Layout/LT/TecmonMain/TecmonMain";
import Header from "./Layout/LT/Header/Header";
import  {Data} from "./Data/TecmonLT/Data";
import Footer from "./Layout/LT/Footer/Footer";
import AboutPage from "./Layout/LT/TecmonMain/About";
import Darbai from "./Layout/LT/TecmonMain/Darbai";
import Paslaugos from "./Layout/LT/TecmonMain/Paslaugos";
import Kainos from "./Layout/LT/TecmonMain/Kainos";

function App() {

  let routes = (
      <Switch>

          <Route path={'/apiemus'} component={AboutPage}/>
          <Route path={'/paslaugos'} component={Paslaugos}/>
          <Route path={'/darbai'} component={Darbai}/>
          <Route path={'/kainos/samata'} component={Darbai}/>
          <Route path={'/kainos'} component={Kainos}/>
          <Route path={'/kontaktai'} component={Darbai}/>
          <Route path={'/'} component={TecmonMain}/>
        <Redirect to={'/'}/>
      </Switch>
  )

  return (
    <div className="App">
        <Header
            data={Data}
        />
        {routes}

        <Footer
            data={Data}
        />


    </div>
  );
}

export default App;
