import React from 'react';
import MainGallery from "../MainGallery/MainGallery";
import './CarouselSlider.css';
import Tel from "../Tel/Tel";

const CarouselSlider = (props) => {

    const Data = props.data

    return (
        <>
            <div className={"main-gallery"}>
                <div className={"main-gallery__wrapper"}>
                    <h2 className={"main-gallery__title"}>{Data.CarouselTitle.Title}</h2>
                </div>
                <MainGallery
                    data = {Data.MainGalleryData}
                />
                <Tel text = {Data.CarouselTitle.Button}/>
                <p className={"main-gallery__text"}>{Data.CarouselTitle.Text}</p>
            </div>
        </>
    )
};
export default CarouselSlider;