import React from 'react';
import  {Data} from "../../../Data/TecmonLT/Data";
import FirstScreen from "../../../Components/FirstScreen/FirstScreen";
import About from "../../../Components/About/About";
import Service from "../../../Components/Service/Service";
import ContactUs from "../../../Components/ContactUs/ContactUs";
import TitleSubtitlePicture from "../../../Components/TitleSubtitlePicture/TitleSubtitlePicture";
import SimpleText from "../../../Components/SimpleText/SimpleText";
import FeatureItem from "../../../Components/FeatureItem/FeatureItem";

const Kainos = () => {


  return (
   <main>
        <TitleSubtitlePicture
            data={ Data.KainosPage }
        />

        <FeatureItem
            data={Data.KainosPage.FeatureItem}
        />
   </main>
  );
 }


export default Kainos;