import React, {useState, useEffect} from 'react';
import Logo from "../../../Components/Logo/Logo";
import NavBar from "../../../Components/NavBar/NavBar";
import Tel from "../../../Components/Tel/Tel";
import './Header.css';

const Header = props => {

  const [shadow, setShadow] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);


  useEffect(() => {
      function  onScroll() {
          let currentPosition = window.pageYOffset;
          if (currentPosition > scrollTop) {
              setShadow(true)
          } else {
              setShadow(false)
          }
          setScrollTop(currentPosition => 0 ? 0 : currentPosition)
      }
      window.addEventListener("scroll", onScroll);
      return () => window.removeEventListener("scroll", onScroll)
  }, [scrollTop])

  const Data = props.data

  return (
   <header className={shadow ? 'header header__shadow' : 'header'}>
       <div className={"header__wrapper"}>
           <Logo
               img={Data.Logo.img}
               alt={Data.Logo.alt}
               to={Data.Logo.to}
           />
           <NavBar
               data={Data.NavData}
           />
           <Tel
               tel={Data.Tel.Num}
               text={Data.Tel.Text}
           />
       </div>

   </header>
  );
 }


export default Header;