import React from 'react';
import './Service.css'

const Service = props => {

    const Data = props.data
    const Services = Data.Service.serviceItem

    const renderServices = Services.map((item, index) => 
        <div className={'features__item'} key={index}>
                <img src={item.itemPic} alt={item.itemTitle + 'picture'}/>
                <h4>{item.itemTitle}</h4>
                <p>{item.itemText}</p>
                <span>{item.itemPrice}</span>
        </div>
    )


  return (
   <div className={'service'}>
       <div className={'service__wrapper'}>
           <h3 className={'service__title'}>{Data.Service.Title}</h3>
           <div className={'features__wrapper'}>
               {renderServices}
           </div>
       </div>
   </div>
  );
 }


export default Service;